import css from "@emotion/css"
import { Column, Columns, Container, Section } from "bloomer"
import PropTypes from "prop-types"
import React from "react"

import Layout from "../layouts"
import { ModelsCarouselItem } from "./model-carousel-item.component"

const ArchiveTemplate = props => {
  const { posts } = props || null
  return (
    <Layout>
      <Section
        css={css`
          margin-top: 6rem;
        `}
      >
        <Container>
          <Columns isMultiline>
            {posts.edges &&
              posts.edges.map(({ node: post }) => (
                <Column key={post.id} isSize={6}>
                  <a href={`/models/${post.slug}`}>
                    <ModelsCarouselItem model={post} />
                  </a>
                </Column>
              ))}
          </Columns>
        </Container>
      </Section>
    </Layout>
  )
}
ArchiveTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

export default ArchiveTemplate
