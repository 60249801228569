import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ArchiveTemplate from "../components/archive-template.component"

export const CategoryTemplate = props => {
  const { data } = props
  if (!data) {
    return null
  }
  const { posts } = props.data
  if (!posts) {
    return null
  }
  return <ArchiveTemplate posts={posts} title={`Models`} />
}

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CategoryTemplate
export const categoryQuery = graphql`
  query CategoryBySlug($slug: String) {
    posts: wordpressPost(categories: { elemMatch: { slug: { eq: $slug } } }) {
      ...WPPostFields
    }
    category: wordpressCategory(slug: { eq: $slug }) {
      id
      name
      slug
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
